<template>
  <div>
    <van-popup v-model="show" position="left" :style="{ width: '65%', height: '100%' }" @close="close">
      <div class="box">
        <div class="sideItem" @click="toHome('术式专区')">
          <img class="icon" src="@/assets/images/sszq_navi_icon@2x.png" />
          <span class="sideName">术式专区</span>
          <img class="next" src="@/assets/images/next_navi_icon@2x.png" />
        </div>
        <div class="sideItem" @click="toSuturePrefecture('缝合新材料专区', 'fhxcl|')">
          <img class="icon" src="@/assets/images/fhxcl_navi_icon@2x.png" />
          <span class="sideName">缝合新材料专区</span>
          <img class="next" src="@/assets/images/next_navi_icon@2x.png" />
        </div>
        <!-- <div class="sideItem" @click="toSSIPrefecture('手术切口感染', 'ssqkgr|')">
          <img class="icon" src="@/assets/images/ssqk_navi_icon@2x.png" />
          <span class="sideName">预防手术切口感染(SSI)专区</span>
          <img class="next" src="@/assets/images/next_navi_icon@2x.png" />
        </div> -->
        <!-- <div class="sideItem" @click="toDigitalSummit('数字化峰会', 'szhfh|')">
          <img class="icon" src="@/assets/images/szh.png" />
          <span class="sideName">数字化峰会</span>
          <img class="next" src="@/assets/images/next_navi_icon@2x.png" />
        </div> -->
        <!-- <div class="sideItem" @click="toOperativeMonth('缝合有范', 'jcssy|')">
          <img class="icon" src="@/assets/images/jcssy@2x.png" />
          <span class="sideName">缝合有范</span>
          <img class="next" src="@/assets/images/next_navi_icon@2x.png" />
        </div> -->
        <div class="sideItem" @click="toPlus20('抗菌20周年', 'plus20|')">
          <img class="icon" src="@/assets/images/20zn.png" />
          <span class="sideName">抗菌20周年</span>
          <img class="next" src="@/assets/images/next_navi_icon@2x.png" />
        </div>
        <!-- <div class="sideItem" @click="totest">
          <img class="icon" src="@/assets/images/zxkj_navi_icon@2x.png" />
          <span class="sideName">测试链接</span>
          <img class="next" src="@/assets/images/next_navi_icon@2x.png" />
        </div> -->
        <!-- <div class="sideItem" @click="toNeedleworkPrefecture('针线科技专区', '')">
          <img class="icon" src="@/assets/images/zxkj_navi_icon@2x.png" />
          <span class="sideName">针线科技专区</span>
          <img class="next" src="@/assets/images/next_navi_icon@2x.png" />
        </div> -->
        <!-- <div class="sideItem" @click="toActivity('系列活动')">
          <img class="icon" src="@/assets/images/xlhd_icon.png" />
          <span class="sideName">系列活动</span>
          <img class="next" src="@/assets/images/next_navi_icon@2x.png" />
        </div> -->
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixin from '../common/mixin/mixins'
export default {
  name: 'Sidebar',
  props: {
    sidebarShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: this.sidebarShow
    }
  },
  mixins: [mixin],
  computed: {
    ...mapState(['userInfo']),
  },
  watch: {
    sidebarShow(val) {
      this.show = val
    },
  },
  methods: {
    close() {
      this.$emit('popupClose', false);
    },
    toHome(name) {
      if(this.userInfo.SubjectCode) {
        this.$router.push({
          path: 'department',
          query: {
            code: this.userInfo.SubjectCode
          }
        })
      } else {
        this.$router.push('/home');
      }
      this.BuriedPoint(1, name, this.userInfo.SubjectCode, '/department?code='+this.userInfo.SubjectCode);
      this.show = false;
    },
    toSuturePrefecture(name, TCode) {
      if (this.$route.path == '/suturePrefecture') {
        this.show = false;
      } else {
        this.BuriedPoint(1, name, TCode, '/suturePrefecture');
        this.$router.push('/suturePrefecture');
      }
    },
    toSSIPrefecture(name, TCode) {
      if (this.$route.path == '/SSIPrefecture') {
        this.show = false;
      } else {
        this.BuriedPoint(1, name, TCode, '/SSIPrefecture');
        this.$router.push('/SSIPrefecture');
      }
    },
    toNeedleworkPrefecture(name, TCode) {
      this.BuriedPoint(1, name, TCode, '/blank');
      location.href = process.env.VUE_APP_BASE_URL + '/blank';
    },
    toDigitalSummit(name, TCode) {
      if (this.$route.path == '/digitalSummit') {
        this.show = false;
      } else {
        this.BuriedPoint(1, name, TCode, '/digitalSummit');
        this.$router.push('/digitalSummit');
      }
    },
    toOperativeMonth(name, TCode) {
      this.BuriedPoint(1, name, TCode, '/operativeMonth');
      this.$router.push('/operativeMonth');
    },
    toPlus20(name, TCode) {
      this.BuriedPoint(1, name, TCode, '/plus20');
      this.$router.push('/plus20');
    },
    toActivity(name) {
      this.BuriedPoint(1, name, '', '/activityZone');
      this.$router.push('/activityZone');
    },
    totest() {
      this.$router.push({
        path: 'default',
        query: {
          type: 3
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  padding: 20px 0;
  .sideItem {
    position: relative;
    display: flex;
    align-items: center;
    width: 90%;
    height: 68px;
    border-radius: 68px;
    background-color: rgba(245, 245, 245, 0.8);
    margin: 10px auto;
    padding: 0 10px;
    box-sizing: border-box;
    .icon {
      width: 48px;
      height: 48px;
      margin-right: 10px;
    }
    .sideName {
      display: inline-block;
      width: 120px;
      font-size: 16px;
      color: #333333;
    }
    .next {
      position: absolute;
      right: 20px;
      width: 6px;
    }
  }
}
</style>